import React, { useState } from "react";
import styled from "styled-components";

interface BookData {
  title: string;
  subtitle?: string;
  description?: string;
  type: string;
  urlFile?: string;
  urlImage?: string;
  category: string;
  language: string;
}

const BookForm: React.FC = () => {
  const [bookData, setBookData] = useState<BookData>({
    title: "",
    subtitle: "",
    description: "",
    type: "",
    urlFile: "",
    urlImage: "",
    category: "",
    language: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setBookData({ ...bookData, [name]: value });
    // Define o tipo automaticamente baseado no idioma selecionado
    if (name === "language") {
      let typeValue = "";
      if (value === "English") typeValue = "Download";
      else if (value === "Spanish") typeValue = "Descargar";
      else if (value === "Portuguese") typeValue = "Baixar";

      setBookData((prevData) => ({
        ...prevData,
        language: value,
        type: typeValue,
      }));
    }
  };

  const handleBookSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:3333/books", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bookData),
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.msg);
        setBookData({
          ...bookData,
          title: "",
          subtitle: "",
          description: "",
          urlFile: "",
          urlImage: "",
        });
      } else {
        alert(result.msg || "Erro ao cadastrar o livro.");
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      alert("Erro ao conectar com o servidor.");
    }
  };

  return (
    <FormContainer onSubmit={handleBookSubmit}>
      <FormField>
        <Label>Title:</Label>
        <Input
          type="text"
          name="title"
          value={bookData.title}
          onChange={handleChange}
          required
        />
      </FormField>
      <FormField>
        <Label>Description:</Label>
        <Textarea
          name="description"
          value={bookData.description}
          onChange={handleChange}
          required
        />
      </FormField>

      <FormField>
        <Label>URL File:</Label>
        <Input
          type="text"
          name="urlFile"
          value={bookData.urlFile}
          onChange={handleChange}
          required
        />
      </FormField>

      <FormField>
        <Label>URL Image:</Label>
        <Input
          type="text"
          name="urlImage"
          value={bookData.urlImage}
          onChange={handleChange}
          required
        />
      </FormField>
      <FormField>
        <Label>Category:</Label>
        <Select
          name="category"
          value={bookData.category}
          onChange={handleChange}
          hasValue={!!bookData.category}
          required
        >
          <option value="">Selecionar Categoria</option>
          <option value="Rico">Rico</option>
          <option value="Persuasao">Persuasão</option>
        </Select>
      </FormField>
      <FormField>
        <Label>Language:</Label>
        <Select
          name="language"
          value={bookData.language}
          onChange={handleChange}
          hasValue={!!bookData.language}
          required
        >
          <option value="">Selecione o idioma</option>
          <option value="English">English</option>
          <option value="Spanish">Spanish</option>
          <option value="Portuguese">Portuguese</option>
        </Select>
      </FormField>
      <FormField>
        <Label>Type:</Label>
        <Input
          type="text"
          name="type"
          value={bookData.type}
          onChange={handleChange}
          required
          readOnly
        />
      </FormField>
      <SubmitButton type="submit">Cadastrar Livro</SubmitButton>
    </FormContainer>
  );
};

export default BookForm;
const Select = styled.select<{ hasValue: boolean }>`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: ${({ hasValue }) => (hasValue ? "#d4edda" : "#fff")};
`;

// Styled components
const FormContainer = styled.form`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-family: "Roboto";
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #888686;
  border-radius: 4px;
  font-size: 16px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #888686;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;
