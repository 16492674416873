import React, { useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Container, Content } from "./styles";
import BookCard from "../../components/BookCard";
import BookCardRead from "../../components/BookCardRead";
import { HeaderBook } from "../../components/HeaderBook";

interface Livro {
  tipo: "ler" | "baixar" | "download" | "descargar";
  id: number;
  imagem: string;
  titulo: string;
  subtitle: string;
  descricao: string;
  url: string;
}

interface BookCarouselProps {
  livros: Livro[];
  handleLerAgora: (id: number) => void;
  handleBaixar?: (url: string) => void;
  tipo: string;
  showArrows?: boolean;
  flexWrap?: "wrap" | "nowrap";
}

const BookRead: React.FC<BookCarouselProps> = ({
  livros,
  handleLerAgora,
  handleBaixar,
  tipo,
  showArrows,
  flexWrap,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemWidth = 320;

  const handleScrollLeft = () => {
    const newIndex = (currentIndex - 1 + livros.length) % livros.length;
    setCurrentIndex(newIndex);
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: -itemWidth, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    const newIndex = (currentIndex + 1) % livros.length;
    setCurrentIndex(newIndex);
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: itemWidth, behavior: "smooth" });
    }
  };

  return (
    <Container>
      {showArrows && (
        <FaAngleLeft className="left" onClick={handleScrollLeft} />
      )}
      <Content ref={contentRef} flexWrap={flexWrap}>
        {livros.map((livro) => (
          <BookCardRead
            key={livro.id}
            imagem={livro.imagem}
            titulo={livro.titulo}
            subtitle={livro.subtitle}
            descricao={livro.descricao}
            url={livro?.url}
            onAcao={() => {
              livro.tipo === "baixar"
                ? handleBaixar?.(livro?.url)
                : handleLerAgora(livro.id);
            }}
            tipo={livro.tipo}
          />
        ))}
      </Content>
      {showArrows && (
        <FaAngleRight className="right" onClick={handleScrollRight} />
      )}
    </Container>
  );
};

export default BookRead;
