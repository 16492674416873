import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";

import {
  Container,
  Title,
  Paragraph,
  Header,
  InButton,
  DeButton,
  ContentButtons,
  Content,
  Card,
} from "./styles";
import { Toggle } from "../Toggle";
import { useTheme } from "../../hooks/theme";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
const BookPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);

  const [fontSize, setFontSize] = useState<number>(16);
  const { toggleTheme, theme } = useTheme();
  const [darkTheme, setDarkTheme] = useState(() =>
    theme.title === "dark" ? true : false
  );
  const handleChangeTheme = () => {
    setDarkTheme(!darkTheme);
    toggleTheme();
  };

  const aumentarFonte = () => {
    setFontSize((prevSize) => prevSize + 2);
  };

  const diminuirFonte = () => {
    setFontSize((prevSize) => Math.max(prevSize - 2, 12));
  };

  const openBar = () => {
    setOpen(!open);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { language } = useTranslations();
  const livros = translations[language].books?.readBooks;
  const livro = livros?.find((livro) => livro.id === Number(id));

  const livrosStudy = translations[language].books?.studyTips;
  const livroStudy = livrosStudy?.find((livro) => livro.id === Number(id));

  return (
    <Container>
      <Content>
        {/* <Header> */}
        {open && (
          <Card>
            <ContentButtons>
              <InButton onClick={aumentarFonte}>Aumentar fonte</InButton>
              <DeButton onClick={diminuirFonte}>Diminuir fonte</DeButton>
              <div className="contentTheme">
                <Toggle checked={darkTheme} onChange={handleChangeTheme} />
                <p className="title">Alterar Thema</p>
              </div>
            </ContentButtons>
          </Card>
        )}
        {/* </Header> */}
        <div>
          {/* Adiconar noticias recentes */}
          <Title>
            {(livro && livro?.titulo) || (livroStudy && livroStudy.titulo)}
          </Title>

          <Paragraph
            fontSize={fontSize}
            style={{
              whiteSpace: "pre-line",
              marginBottom: "1em",
            }}
          >
            {(livro &&
              livro?.conteudo.map((item: any) => {
                return (
                  <p key={item} style={{ marginBottom: "20px" }}>
                    {item.paragrafo}
                  </p>
                );
              })) ||
              (livroStudy &&
                livroStudy?.conteudo.map((study: any) => {
                  return (
                    <p key={study} style={{ marginBottom: "20px" }}>
                      {study.paragrafo}
                    </p>
                  );
                }))}
          </Paragraph>
        </div>
      </Content>
    </Container>
  );
};

export default BookPage;
