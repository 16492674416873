import styled from "styled-components";

export const Container = styled.section`
  background: ${(props) => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
  z-index: 101;
  height: 65px;
  font-size: 0.6rem;
  top: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  z-index: 9999;

  .baricon {
    display: none;
  }
  .toggle-des {
    padding-left: 1rem;
  }
  @media (max-width: 1208px) {
    padding: 1rem 1.7rem;

    .baricon {
      width: 40px;
      font-size: 2rem;
      cursor: pointer;
      display: block;
      color: ${(props) => props.theme.colors.warning};
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  padding-left: 4rem;
  justify-content: center;
  width: 100%;
  transition: ease 0.3s;

  /* Estilo do item de navegação */
  li {
    list-style: none;
    margin-right: 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    font-family: "Poppins", sans-serif;
    color: ${(props) => props.theme.colors.font};
  }

  /* Estilo do link dentro do item de navegação */
  li a {
    text-decoration: none;
    list-style: none;
    color: ${(props) => props.theme.colors.font};
    font-weight: 700;
    padding: 0.8rem;
    margin: 10px;
    border-radius: 10px;
    border: 1.8px red solid;
    transition: 0.3s;
  }

  /* Estilo para o link ativo */
  li a.active {
    background: #640f00; /* Cor do link ativo (exemplo: Tomate) */
    font-weight: bold; /* Deixa o link ativo em negrito */
  }

  /* Estilo para o hover do link */
  li a:hover {
    color: ${(props) => props.theme.colors.info};
    font-weight: bold;
  }

  /* Inicialmente, no mobile, o menu fica invisível */
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%); /* Fora da tela à direita */

  /* Quando a classe 'open' for aplicada, o menu se move para dentro e fica visível */
  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0); /* Move para a posição visível */
  }

  /* Estilos para telas pequenas (mobile) */
  @media (max-width: 1200px) {
    padding-left: 0rem;
    position: absolute;
    width: 50%;
    height: auto;
    top: 4.2rem;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    li {
      width: 90%;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 10px;
      margin-bottom: 1rem;
      padding: 0.8rem;
      text-align: left;
      border: none;
      flex-direction: column;
      font-size: 1.1rem;
    }

    li a {
      border: none;
    }

    li a.active {
      background: #640f00; /* Cor do link ativo */
      font-weight: bold;
    }

    li a:hover {
      color: ${(props) => props.theme.colors.info};
    }
  }

  /* No desktop, o menu é exibido normalmente (sem animação) */
  @media (min-width: 1200px) {
    display: flex;
    position: static;
    transform: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
  }
`;

export const ThemeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.div`
  color: ${(props) => props.theme.colors.font};
  width: 10rem;
  padding-left: 2rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  @media (max-width: 1200px) {
    display: none;
  }
`;
