// VideoPlayer/index.tsx
import React from "react";
import { Container, StyledIframe } from "./styles";

interface VideoPlayerProps {
  src?: string;
  width?: string;
  height?: string;
  title?: string;
  livros?: Livro[];
}
interface Livro {
  urlAudio?: string;
  _id: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  width = "414px",
  height = "414px",
  title = "Video Player",
  livros,
  src,
}) => {
  if (!src) {
    return <p>URL do vídeo não encontrada.</p>; // Caso o src esteja vazio ou inválido
  }
  return (
    <Container>
      <StyledIframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${src}`}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Container>
  );
};

export default VideoPlayer;
