const urlApi =
  process.env.API_BACKEND_UR === "development"
    ? "https://back-end-ebooks-lucasnogueiradevs-projects.vercel.app"
    : "https://back-end-ebooks-lucasnogueiradevs-projects.vercel.app";

const firstPage =
  process.env.API_BACKEND_UR === "production"
    ? "https://back-end-ebooks-lucasnogueiradevs-projects.vercel.app"
    : "https://back-end-ebooks-lucasnogueiradevs-projects.vercel.app";

// eslint-disable-next-line import/no-anonymous-default-export
export default { urlApi, firstPage };
// https://back-end-ebooks-lucasnogueiradevs-projects.vercel.app
//http://localhost:3333 //
