import React from "react";
import {
  CardImage,
  CardContent,
  CardTitle,
  CardDescription,
  CardButton,
  CardSubTitle,
  CardContainer,
} from "./style";
import { useTranslations } from "../../contexts/TranslationContext";
import translations from "../../data/translations";
import { HeaderBook } from "../HeaderBook";

interface BookCardProps {
  imagem: string;
  titulo: string;
  descricao: string;
  subtitle: string;
  tipo: "ler" | "baixar" | "download" | "descargar";
  onAcao: (tipo: "ler" | "baixar" | "download" | "descargar") => void;
  url?: string;
}

const BookCardRead: React.FC<BookCardProps> = ({
  imagem,
  titulo,
  descricao,
  onAcao,
  subtitle,
  tipo,
}) => {
  const { language } = useTranslations();

  const layoutTranslations = {
    button: translations[language]?.layout?.button || "Action",
    buttonSecudary: translations[language]?.layout?.buttonSecudary || "Action",
  };
  const handleClick = () => {
    onAcao(tipo);
  };

  return (
    <CardContainer>
      <CardImage src={imagem} alt={titulo} />
      <CardContent>
        <div>
          <CardTitle $tipo={tipo}>{titulo}</CardTitle>
          <CardSubTitle>{subtitle}</CardSubTitle>
          <CardDescription>{descricao}</CardDescription>
        </div>
        <div className="footer-button">
          <CardButton onClick={handleClick} $tipo={tipo}>
            {tipo === "baixar"
              ? "Baixar"
              : tipo === "download"
              ? "Download"
              : tipo === "descargar"
              ? layoutTranslations.buttonSecudary
              : tipo === "ler"
              ? layoutTranslations.button
              : ""}
          </CardButton>
        </div>
      </CardContent>
    </CardContainer>
  );
};

export default BookCardRead;
