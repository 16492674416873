import { Footer } from "../../pages/footer";
import { Header } from "../Header";
import { HeaderBook } from "../HeaderBook";
import { HeaderRead } from "../HeaderRead";
import { Container } from "./styles";

interface IPropsLayout {
  children: React.ReactNode;
}

export const LayoutRead = ({ children }: IPropsLayout) => {
  return (
    <Container>
      <HeaderRead />
      <main>{children}</main>
    </Container>
  );
};
