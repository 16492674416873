// src/components/LanguageSelector/index.tsx
import React, { useState } from "react";
import { useTranslations } from "../../contexts/TranslationContext";
import {
  SelectorContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  FlagImage,
} from "./styles";

const LanguageSelectorRead: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, setLanguage } = useTranslations();

  const languages = [
    {
      value: "pt",
      label: "Português",
      flag: "/brasil.png",
    },
    {
      value: "en",
      label: "English",
      flag: "/ingles.png",
    },
    {
      value: "es",
      label: "Español",
      flag: "/espanhol.png",
    },
  ];

  const handleSelect = (value: string) => {
    setLanguage(value as "pt" | "en" | "es");
    setIsOpen(false);
  };

  // Encontre o idioma selecionado
  const selectedLanguage = languages.find((lang) => lang.value === language);

  return (
    <SelectorContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedLanguage ? (
          <>
            <FlagImage
              src={selectedLanguage.flag}
              alt={selectedLanguage.label}
            />
            {selectedLanguage.label}
          </>
        ) : (
          "Selecione o idioma"
        )}
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {languages.map((lang) => (
            <DropdownItem
              key={lang.value}
              onClick={() => handleSelect(lang.value)}
            >
              <FlagImage src={lang.flag} alt={lang.label} />
              {lang.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </SelectorContainer>
  );
};

export default LanguageSelectorRead;
