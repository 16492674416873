import { CopyrightText, FooterContainer, SocialLinks } from "./styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <CopyrightText>
        <a
          href="https://wa.me/13991445362?text=suporte"
          target="_blank"
          rel="noreferrer"
          className="links"
        >
          suporte
        </a>
      </CopyrightText>
      <SocialLinks>
        <a
          href="https://wa.me/13991445362?text=Quero%20indicar%20um%20livro"
          target="_blank"
          rel="noreferrer"
          className="links"
        >
          Indicar um livro
        </a>
      </SocialLinks>
    </FooterContainer>
  );
};
