import styled from "styled-components";
export interface CardButtonProps {
  $tipo: "ler" | "baixar" | "download" | "descargar"; // Define os tipos que o botão pode ter
}
export const CardContainer = styled.div`
  position: relative;
  width: 300px; /* Ajuste conforme necessário */
  min-width: 300px;
  height: 400px; /* Ajuste conforme necessário */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
  margin: 1rem 0.5rem;
  &:hover {
    transform: scale(1.05);
  }

  .card-des {
    height: 10rem;
  }
  @media (max-width: 1200px) {
    width: 130px;
    min-width: 150px;
    height: 200px;
    /* background: ${(props) => props.theme.colors.tertiary}; */
    justify-content: center;
    margin: 1rem auto;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px ${(props) => props.theme.colors.secondary} solid;
  border-radius: 10px;
`;

export const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.91);
  color: white;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s;

  ${CardContainer}:hover & {
    opacity: 1;
  }
  .footer-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 15rem;
  }
`;

export const CardTitle = styled.h2<CardButtonProps>`
  font-size: 1.3rem;
  margin: 0;
  font-weight: bold;
  color: ${(props) =>
    props.$tipo === "baixar" ||
    props.$tipo === "descargar" ||
    props.$tipo === "download"
      ? props.theme.colors.tertiary
      : props.theme.colors.warning};
  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
`;

export const CardSubTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  padding: 4.5px 0;
  color: ${(props) => props.theme.colors.info};
`;

export const CardDescription = styled.p`
  font-size: 0.8rem;
  margin: 0px 0 10px 0;
  line-height: 1.1rem;
  font-weight: 500;
  max-height: 30px;
  max-height: calc(1.1rem * 5); /* Altura máxima para 4 linhas */
  font-family: "Montserrat", sans-serif;

  display: -webkit-box; /* Necessário para usar o clamp */
  -webkit-line-clamp: 5; /* Limita o texto a 4 linhas */
  -webkit-box-orient: vertical;
  overflow: hidden; /* Oculta o texto excedente */
  text-overflow: ellipsis; /* Adiciona reticências ao final do texto */
  white-space: normal; /* Permite múltiplas linhas */
  @media (max-width: 1200px) {
    margin: 0px 0 4px 0;
    font-size: 0.5rem;
    line-height: 1rem;
    overflow-y: scroll;
    padding-right: 10px;
    /* background: blue; */
    max-height: calc(1.1rem * 4);
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
  }
`;

export const CardButton = styled.button<CardButtonProps>`
  padding: 15px 15px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  bottom: 0;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 60px;
  border-radius: 50%;
  transition: 0.3s;
  &:hover {
    background-color: #ccc;
    transform: scale(1.2);
  }
  @media (max-width: 1200px) {
    font-size: 0.8rem;
    height: 30px;
    width: 30px;
    margin-bottom: 15px;
  }
`;
