// VideoPlayer/styles.ts
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  /* justify-content: center;*/
  align-items: center;
  padding: 1rem;
  flex-direction: row;
`;

export const StyledIframe = styled.iframe<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: none;
  background: #181717;
  width: 900px;
  border-radius: 10px;
`;
