import { BrowserRouter, Routes, Route } from "react-router-dom";
// import HomePage from "../pages/home/Home";
import React from "react";
import { Layout } from "../components/Layout/Layout";
import { Header } from "../components/Header/index";
import BookPage from "../components/BookPage/BookPage";
import { SignIn } from "../pages/signIn";
import { AuthProvider } from "../contexts/AuthContexts";
import Book from "../pages/book";
import { Footer } from "../pages/footer";
import PrivateRoute from "./PrivateRoute"; // Ajuste o caminho conforme sua estrutura de arquivos
import { LayoutBook } from "../components/LayoutBook/Layout";
import AllPage from "../pages/allEbooks";
import HomePage from "../pages/home/Home";
import { TranslationProvider } from "../contexts/BooksContext";
import BookForm from "../pages/createBooks";
import AudioBooks from "../pages/audioEbooks";
import BookPageAudio from "../components/BookPageAudio/BookPageAudio";
import { LayoutRead } from "../components/LayoutRead/Layout";

export function AppRoutes() {
  return (
    <TranslationProvider>
      {/* {!noHeaderRoutes.includes(location.pathname) && <Header />} */}
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/auth/login" element={<SignIn />} />
            {/* Rotas privadas */}
            {/* <Route element={<PrivateRoute />}> */}
            {/* <Route
              path="/"
              element={
                <Layout>
                  <HomePage />
                </Layout>
              }
            /> */}
            <Route
              path="/books-summary"
              element={
                <LayoutRead>
                  <HomePage />
                </LayoutRead>
              }
            />
            <Route
              path="/books-summary/:id"
              element={
                <LayoutBook>
                  <BookPage />
                </LayoutBook>
              }
            />
            {/* </Route> */}
            <Route
              path="/"
              element={
                <Layout>
                  <AllPage />
                </Layout>
              }
            />
            <Route
              path="/books-audios"
              element={
                <Layout>
                  <AudioBooks />
                </Layout>
              }
            />
            <Route
              path="/books-audios/:id/:urlAudio"
              element={
                <LayoutBook>
                  <BookPageAudio />
                </LayoutBook>
              }
            />

            <Route
              path="/cadastrar"
              element={
                <Layout>
                  <BookForm />
                </Layout>
              }
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </TranslationProvider>
  );
}
