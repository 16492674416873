import styled from "styled-components";

export const Container = styled.section`
  padding: 3rem 20px;
  max-width: 800px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 19px;
  margin: 3rem auto;
  overflow: hidden;

  .baricon {
    /* display: none; */
    position: fixed;
    display: flex;
    right: 28px;
    top: 22px;
    z-index: 99999;
    width: 2rem;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.warning};
  }
  @media (max-width: 1208px) {
    width: 87%;
  }
`;
export const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 200px;
  background-color: white;
  position: fixed; /* Faz o card flutuar */
  top: 50px; /* Ajuste a posição vertical */
  right: 20px; /* Ajuste a posição horizontal */
  z-index: 1000; /* Garante que o card esteja acima de outros elementos */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;
export const Content = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari e Opera
  }
`;

export const Title = styled.h1`
  font-size: 2.5em;
  color: ${(props) => props.theme.colors.warning};
  text-align: center;
  font-family: "Poppins", sans-serif;
`;

export const Paragraph = styled.p<{ fontSize: number }>`
  font-size: 1rem;
  line-height: 1.6;
  color: ${(props) => props.theme.colors.font};
  margin: 15px 0;
  font-family: "Roboto", sans-serif;
  font-size: ${(props) => props?.fontSize}px;
  text-align: justify;
`;

export const Header = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;
export const ContentButtons = styled.article`
  flex-direction: column;
  display: flex;
  gap: 1rem;
  .contentTheme {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    margin-left: -12px;
  }
`;
export const InButton = styled.button`
  padding: 8px 15px;
  background-color: #ff5733;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  bottom: 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;

  &:hover {
    background-color: #c44e2d;
  }
`;
export const DeButton = styled.button`
  padding: 8px 15px;
  background-color: #ff5733;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  bottom: 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;

  &:hover {
    background-color: #c44e2d;
  }
`;
