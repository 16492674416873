import styled from "styled-components";

export const Container = styled.section`
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  padding: 0rem 1.5rem;
  color: ${(props) => props.theme.colors.warning};
  z-index: 101;
  height: 65px;
  font-size: 0.6rem;
  top: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  /* justify-content: center; */

  @media (max-width: 1208px) {
  }
`;
export const BackButton = styled.button`
  padding: 10px 15px;
  background-color: #ff5733;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  bottom: 0;
  margin-right: 1.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;

  &:hover {
    background-color: #c44e2d;
  }
`;

export const ThemeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.div`
  color: ${(props) => props.theme.colors.font};
  width: 10rem;
  padding-left: 2rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  @media (max-width: 1200px) {
    display: none;
  }
`;
