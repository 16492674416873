import React, { useState, useContext, FormEvent } from "react";
import { Container, Form, Logo } from "./styles";
import FilledInput from "@mui/material/FilledInput";
import { Button, InputAdornment } from "@mui/material";
import { HiOutlineUser } from "react-icons/hi";
import { SlLock } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { AuthContext, SignInProps } from "../../contexts/AuthContexts";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const data = {
      email,
      password,
    };
    return signIn(data);
  };
  // const handleSubmit = async (event: FormEvent) => {
  //   event.preventDefault();
  //   console.log("entrou");
  //   const data = {
  //     email,
  //     password,
  //   };
  //   console.log("entrou 2", data);
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:3333/auth/login",
  //       data
  //     );
  //     console.log(response);
  //     const { token } = response.data;
  //     toast.success(response.data.msg);

  //     localStorage.setItem("token", token);
  //     navigate("/");
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.msg);
  //     console.error("Erro ao logar:", error);
  //   }
  // };

  return (
    <Container>
      <ToastContainer />
      <div className="container">
        <div className="container2">
          <Form onSubmit={handleSubmit}>
            <div className="img">
              <img src="logo1.png" width={300} alt="" />
            </div>
            <Logo>
              <p>Login</p>
            </Logo>
            <FilledInput
              id="filled-adornment-weight"
              className="input"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <HiOutlineUser
                    style={{
                      color: "#E44C4E",
                      fontSize: "1.3rem",
                    }}
                  />
                </InputAdornment>
              }
            />
            <FilledInput
              id="filled-adornment-weight"
              className="input"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SlLock
                    style={{
                      color: "#E44C4E",
                      fontSize: "1.3rem",
                    }}
                  />
                </InputAdornment>
              }
            />
            <div className="content-bottom">
              <Button
                variant="contained"
                className="buttom"
                // color="warning"
                type="submit"
              >
                Entrar
              </Button>
            </div>
          </Form>
        </div>
        <div className="container1">
          <img
            src="/libros-capa.jpg"
            alt="Capa do Livro"
            className="img-banner"
          />
        </div>
      </div>
    </Container>
  );
};
