import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  margin: auto;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

interface ContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flexDirection?: "row" | "column";
  flexWrap?: "wrap" | "nowrap";
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 88%;
  margin: auto;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
`;
export const Loading = styled.div`
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  p {
    color: ${(props) => props.theme.colors.font};
  }
`;
