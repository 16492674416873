import React from "react";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #111111;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
`;

export const CopyrightText = styled.p`
  margin: 0;
  font-size: 14px;
  list-style: none;
  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 1rem;
    &:hover {
      color: #61dafb;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 15px;

  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 1rem;
    &:hover {
      color: #61dafb;
    }
  }
`;
