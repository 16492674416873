import React from "react";
import {
  CardImage,
  CardContent,
  CardTitle,
  CardDescription,
  CardButton,
  CardSubTitle,
  CardContainer,
  GlobalStyle,
} from "./style";
import { useTranslations } from "../../contexts/TranslationContext";
import translations from "../../data/translations";

interface BookCardProps {
  tipo: "ler" | "baixar" | "download" | "descargar";
  onAcao: (tipo: "ler" | "baixar" | "download" | "descargar") => void;
  url?: string;
  _id: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
}

const BookCard: React.FC<BookCardProps> = ({
  urlImage,
  title,
  description,
  onAcao,
  subtitle,
  tipo,
  type,
  urlFile,
}) => {
  const { language } = useTranslations();

  const layoutTranslations = {
    button: translations[language]?.layout?.button || "Action",
    buttonSecudary: translations[language]?.layout?.buttonSecudary || "Action",
  };
  const handleClick = () => {
    window.open(urlFile, "_blank");
  };

  return (
    <CardContainer>
      <CardImage src={urlImage} alt={title} />
      <GlobalStyle />
      <CardContent>
        <div>
          <CardTitle $tipo={tipo}>{title}</CardTitle>
          <CardSubTitle>{subtitle}</CardSubTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className="footer-button">
          <CardButton onClick={handleClick} $tipo={tipo}>
            {type}
          </CardButton>
        </div>
      </CardContent>
    </CardContainer>
  );
};

export default BookCard;
