import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";

class HttpService {
  private instance: AxiosInstance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Interceptor para tratar respostas de erro
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error)) {
          const message = error.response?.data?.msg || "Erro na resposta";
          toast.error(message);
          return Promise.reject(new Error(message));
        } else {
          console.error("Erro inesperado:", error);
          return Promise.reject(new Error("Erro desconhecido"));
        }
      }
    );
  }

  async post<T, R>(url: string, data: T): Promise<R> {
    try {
      const response = await this.instance.post<R>(url, data);
      return response.data;
    } catch (error: any) {
      console.error("Erro ao fazer POST:", error);
      throw new Error(
        error?.response?.data?.message || "Erro ao fazer requisição POST"
      );
    }
  }

  async get<R>(
    url: string,
    params: { language?: string } = {} // Define 'language' como opcional
  ): Promise<R> {
    try {
      // Passa os parâmetros corretamente para a requisição
      const response = await this.instance.get<R>(url, {
        params: params, // Passa os parâmetros diretamente aqui
      });

      return response.data;
    } catch (error: any) {
      console.log("Erro ao fazer GET:", error);
      throw new Error(
        error?.response?.data?.message || "Erro ao fazer requisição GET"
      );
    }
  }
}

export default HttpService;
