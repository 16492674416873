import { useTranslations } from "../../contexts/TranslationContext";
import translations from "../../data/translations";
import {
  Container,
  BannerImage,
  TextOverlay,
  OverlayBackground,
} from "./styles";
import { MdOutlineSummarize } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";
export const Banner = () => {
  const { language } = useTranslations();

  const layoutTranslations = {
    titlebanner: translations[language].layout.titlebanner,
    descBanner: translations[language].layout.descBanner,
  };

  return (
    <Container>
      <BannerImage
        src="PH-1729908082815.png"
        alt="Melhores Livros de Desenvolvimento Pessoal"
      />
      <OverlayBackground />
      <TextOverlay>
        <MdOutlineSummarize className="icon" />

        <h1>{layoutTranslations.titlebanner}</h1>
        <p>{layoutTranslations.descBanner} </p>
        <span className="obs">
          <strong>
            <TiInfoLarge className="icon-info" />
            <h3> AVISO IMPORTANTE! </h3>
          </strong>
          <p className="Info-des">
            Novos resumos estão sendo criados com base nos livros e serão
            adicionados em diariamente. Em breve, todos os resumos estarão
            disponíveis.
          </p>
        </span>
      </TextOverlay>
    </Container>
  );
};
