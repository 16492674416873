import React, { useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Container, Content } from "./styles";
import BookCard from "../BookCard";
import BookCardAudio from "../BookCardAudio/BookCardAudio";

interface Livro {
  _id: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
  urlAudio: string;
}

interface BookCarouselProps {
  livros: Livro[];
  handleLerAgora: (id: string, urlAudio: string) => void;
  showArrows?: boolean;
  flexWrap?: "wrap" | "nowrap";
}

const BookAudio: React.FC<BookCarouselProps> = ({
  livros,
  handleLerAgora,
  flexWrap,
}) => {
  return (
    <Container>
      <Content flexWrap={flexWrap}>
        {livros?.map((livro) => (
          <BookCardAudio
            key={livro?._id}
            urlImage={livro?.urlImage}
            title={livro?.title}
            subtitle={livro?.subtitle}
            description={livro?.description}
            tipo={"download"}
            onAcao={(id: string, urlAudio: string) => {
              handleLerAgora(id, urlAudio);
            }}
            _id={livro?._id}
            type={"Play"}
            urlFile={livro?.urlFile}
            category={livro?.category}
            language={livro?.language}
            urlAudio={livro?.urlAudio}
          />
        ))}
      </Content>
    </Container>
  );
};

export default BookAudio;
