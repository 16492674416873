import { Toggle } from "../Toggle";
import { BackButton, Container, ThemeToggleWrapper, User } from "./styles";

import { useState } from "react";
import { FaChevronRight, FaRegUserCircle } from "react-icons/fa";

// import { AuthContext } from "../../contexts/AuthContexts";
import { useTheme } from "../../hooks/theme";
import LanguageSelector from "../LanguageSelector";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import { useNavigate } from "react-router-dom";
import LanguageSelectorRead from "../LanguageSelectorRead";

export const HeaderBook = () => {
  // const { user, signOut } = useContext(AuthContext);

  const { toggleTheme, theme } = useTheme();
  const [darkTheme, setDarkTheme] = useState(() =>
    theme.title === "dark" ? true : false
  );
  const navigate = useNavigate();
  const { language } = useTranslations();

  const menuTranslations = {
    // home: translations[language].menu.home,
    // about: translations[language].menu.about,
    // myBooks: translations[language].menu.myBooks,
  };
  const handleVoltar = () => {
    navigate(-1); // Volta para a página anterior
  };
  return (
    <Container>
      <BackButton onClick={handleVoltar}>Voltar</BackButton>
      <LanguageSelectorRead />
    </Container>
  );
};
