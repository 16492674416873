import React from "react";
import {
  CardImage,
  CardContent,
  CardTitle,
  CardDescription,
  CardButton,
  CardSubTitle,
  CardContainer,
} from "./style";
import { useTranslations } from "../../contexts/TranslationContext";
import translations from "../../data/translations";
import { useNavigate } from "react-router-dom";
import { GlobalStyle } from "../BookCard/style";

interface BookCardProps {
  tipo: "ler" | "baixar" | "download" | "descargar";
  onAcao: (id: string, urlAudio: string) => void;
  url?: string;
  _id: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
  urlAudio: string;
}

const BookCardAudio: React.FC<BookCardProps> = ({
  urlImage,
  _id,
  title,
  description,
  onAcao,
  subtitle,
  tipo,
  type,
  urlAudio,
}) => {
  const navigate = useNavigate();

  const handleAbrirVideo = (id: string, urlAudio: string) => {
    navigate(`/books-audios/${id}/${urlAudio}`);
  };

  return (
    <CardContainer>
      <CardImage src={urlImage} alt={title} />
      <GlobalStyle />
      <CardContent>
        {/* <div className="footer-button">
          <CardButton $tipo={tipo}>
            <img src="play.webp" width="50px" alt="" />
          </CardButton>
        </div> */}
        <div className="footer-button">
          <CardButton
            onClick={() => handleAbrirVideo(_id, urlAudio)}
            $tipo={tipo}
          >
            <img src="play.webp" width="50px" alt="" />
          </CardButton>
        </div>
        <div className="card-des">
          <CardTitle $tipo={tipo}>{title}</CardTitle>
          <CardSubTitle>{subtitle}</CardSubTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardContent>
    </CardContainer>
  );
};

export default BookCardAudio;
