import * as S from "./styles";
import Book from "../book";
import { useTranslation } from "../../contexts/BooksContext";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { createContext, useEffect, useRef, useState } from "react";
import BarraPesquisa from "../../components/search/BookSearch";
import { BookService, Livro } from "../../services/api";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import { HeaderBook } from "../../components/HeaderBook";
import { Header } from "../../components/Header";
import { BannerBaixar } from "../../components/BannerBaixar";
import ClipLoader from "react-spinners/ClipLoader";

const AllPage = () => {
  const { selectedLanguage } = useTranslation();
  const navigate = useNavigate();

  const handleLerAgora = (id: number) => {
    navigate(`/book/${id}`);
  };

  const [allBooks, setAllBooks] = useState<Livro[]>([]); // Armazena todos os livros
  const [filteredBooks, setFilteredBooks] = useState<Livro[]>([]); // Armazena os livros filtrados
  const [searchQuery, setSearchQuery] = useState<string>(""); // Estado para a pesquisa
  const [loading, setLoading] = useState<boolean>(false);

  // Função para buscar os livros na API
  useEffect(() => {
    if (selectedLanguage) {
      const fetchBooks = async () => {
        setLoading(true); // Começa o carregamento
        try {
          const response = await BookService.getBooks(selectedLanguage);
          setAllBooks(response); // Armazena todos os livros
          setFilteredBooks(response); // Exibe todos os livros inicialmente
        } catch (error) {
          console.error("Erro ao buscar livros:", error);
        } finally {
          setLoading(false); // Finaliza o carregamento
        }
      };
      fetchBooks();
    }
  }, [selectedLanguage]);

  const handleBaixar = (url: string) => {
    window.open(url, "_blank");
  };

  const resources = "Todos os livros para Baixar";

  const handleSearchBooks = (query: string) => {
    setSearchQuery(query);
    const filtered = allBooks.filter(
      (book) =>
        book.title.toLowerCase().includes(query.toLowerCase()) || // Filtra por título
        book.description?.toLowerCase().includes(query.toLowerCase()) || // Filtra por subtítulo
        book.category.toLowerCase().includes(query.toLowerCase()) // Filtra por categoria
    );

    setFilteredBooks(filtered);
  };

  return (
    <S.Container>
      <div className="border-header"></div>
      <BannerBaixar />
      {/* downloadBooks */}
      <S.Content id="cards">
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>
              {/* <h2>Todos os livros para Baixar</h2> */}
              {resources}
            </S.ContentHeader>
            <BarraPesquisa onSearch={handleSearchBooks} />
          </S.ContentTitle>
        </S.Title>

        <S.ContentBook>
          {loading ? (
            <S.Loading className="loading-spinner">
              <ClipLoader color="#36d7b7" loading={loading} size={50} />
              <p> Carregando livros...</p>
            </S.Loading>
          ) : (
            <Book
              livros={filteredBooks}
              handleLerAgora={handleLerAgora}
              handleBaixar={handleBaixar}
              showArrows={false}
              flexWrap="wrap"
            />
          )}
        </S.ContentBook>
      </S.Content>
    </S.Container>
  );
};

export default AllPage;
