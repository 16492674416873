import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  height: 44rem;
  overflow: hidden;
  .icon {
    color: #a80404;
    width: 20rem;
    height: 90px;
  }
  @media (max-width: 1200px) {
    height: 20rem;
  }
  .icon-info {
    width: 1.5rem;
    height: 1.3rem;
    margin-bottom: 1px;
  }
  .Info-des {
    font-size: 1.2rem;
    color: #fff;
  }
  .obs {
    background: #065082;
    width: 50%;
    text-align: center;
    justify-content: center;
    margin: auto;
    padding: 1.5rem 1rem;
    border-radius: 10px;
    flex-direction: column;
    display: flex;
    margin-top: 2rem;
  }
  .obs strong {
    font-weight: bold;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  @media (max-width: 1200px) {
    .obs {
      width: 100%;
      padding: 0.8rem 1rem;
      margin-top: 1rem;
    }
    .Info-des {
      font-size: 0.8rem;
    }
    .obs strong {
      font-size: 0.8rem;
    }
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 130%;
  object-fit: cover;
`;

export const OverlayBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 1rem;
  z-index: 2;
  width: 90%;
  h1 {
    font-size: 4rem;
    margin: 0;
    padding-bottom: 0.6rem;
    font-weight: 700;
    background: linear-gradient(45deg, hsl(0, 100%, 50%), #790404);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 2.4rem;
      margin: 0;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.5rem;
    color: #fff;
  }
  @media (max-width: 1200px) {
    p {
      font-size: 1rem;
    }
  }
`;
