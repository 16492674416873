import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Estilos das setas */
  .left,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    color: ${(props) => props.theme.colors.tertiary};
    cursor: pointer;
    z-index: 1;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }

  @media (max-width: 1200px) {
    .right,
    .left {
      display: none;
    }
  }
`;

interface ContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flexDirection?: "row" | "column";
  flexWrap?: "wrap" | "nowrap";
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 88%;
  margin: auto;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
`;
