import {
  ContextType,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthService } from "../services/api";
// import AuthService from "../services/api";
// import { api } from "../services/apiClient";

type AuthContextData = {
  user?: UserProps;
  autheticated: boolean;
  loading: boolean;
  signIn: (credentials: SignInProps) => Promise<void>;
  // função para logar, recebe as credenciais de SignInProps.
  signOut: () => void;
  signUp: (credentials: SignUpProps) => Promise<void>;
  value?: any;
};

type UserProps = {
  id: string;
  name: string;
  email: string;
};

export type SignInProps = {
  email: string;
  password: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

type SignUpProps = {
  name: string;
  email: string;
  password: string;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [autheticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<UserProps>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  //Logar usuario
  async function signIn({ email, password }: SignInProps) {
    try {
      const response = await AuthService.login(email, password);
      // const { user, token } = response || {};
      console.log("user", user);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("token", token);
      setUser(user);
      setAuthenticated(true); // Atualizado
      toast.success("Logado com sucesso!");
      navigate("/");
    } catch (error: any) {
      console.log(error);
    }
  }

  // 3. Verificar e definir usuário e token no `useEffect`
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (storedUser && token) {
      setUser(JSON.parse(storedUser));
      setAuthenticated(true); // Atualiza autheticated corretamente
      console.log("Usuário autenticado.");
    } else {
      setAuthenticated(false); // Caso não haja usuário ou token
      console.log("Usuário não autenticado.");
    }
    setLoading(false);
  }, []);

  // Cadastrar usuario
  async function signUp({ name, email, password }: SignUpProps) {
    try {
      const response = await AuthService.register(name, email, password); // Ajuste conforme sua API
      toast.success("Cadastrado com sucesso");

      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    } catch (err) {
      console.error("Erro ao cadastrar:", err);
      toast.error("Erro ao cadastrar");
    }
  }
  // Deslogar usuário
  async function signOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(undefined);
    // setAuthenticated(false);
    navigate("/auth/login");
    window.location.reload();
  }

  return (
    <AuthContext.Provider
      value={{ user, autheticated, signIn, loading, signOut, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
}
