import styled, { keyframes } from "styled-components";

const moveToRight = keyframes`

0% {
  opacity: 0;
  transform: translate(-200px);

}
50%{
  opacity: 3;
}
100%{

  transform: translate(0px);
  opacity: 1;
}


`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  .container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
  }

  .container1 {
    width: 60%;
    max-width: 60%;
    height: 100vh;
    max-height: 100%;
    overflow: hidden;
  }

  .image {
    animation: ${moveToRight} 0.5s;
  }
  .image-banner {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
    animation: ${moveToRight} 0.5s;
  }
  .container2 {
    width: 40%;
    display: flex;
    align-items: center;
    margin: auto;
    height: 100vh;
    height: 100%;
  }
  .content-bottom {
    display: flex;
    width: 60%;
    justify-content: start;
  }
  @media (max-width: 1200px) {
    .container2 {
      width: 90%;
      max-height: 100vh;
    }
    .content-bottom {
      display: flex;
      width: 60%;
      justify-content: center;
      margin-top: 1.1rem;
    }
  }
  @media (max-width: 1200px) {
    .container1 {
      display: none;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  align-items: center;
  margin: auto;

  .input {
    width: 60%;
    justify-content: center;
    margin: auto;
    background: #fff;
    animation: ${moveToRight} 0.5s;
  }
  @media (max-width: 1200px) {
    width: 100%;
    height: 65vh;
    border-radius: 30px;
    .input {
      width: 80%;
      animation: ${moveToRight} 0.5s;
    }
  }

  .bottom {
    width: 60%;
    margin-top: 1.3rem;
    justify-content: start;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 1200px) {
    .bottom {
      width: 80%;
    }
    .buttom {
      background: ${(props) => props.theme.colors.warning};
    }
  }

  .buttom {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    padding: 0 30px;
    height: 40px;
    background: ${(props) => props.theme.colors.warning};
  }
  .cad {
    margin-top: 1.2rem;
    p {
      font-size: 1.2rem;
    }
    a {
      color: #486ee5;
    }
  }
  @media (max-width: 1200px) {
    .cad {
      width: 85%;
      gap: 1rem;
      margin-top: 1rem;

      p {
        font-size: 1rem;

        text-align: center;
      }
      a {
        font-weight: 500;
        color: #fc750f;
      }
    }
  }
`;

export const Logo = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  display: flex;
  justify-content: start;
  text-align: start;
  width: 60%;

  p {
    animation: ${moveToRight} 0.5s;
    text-align: start;

    justify-content: start;
    display: flex;
    width: 30%;
    color: ${(props) => props.theme.colors.warning};
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin: auto;
    justify-content: center;

    p {
      text-align: center;
      font-size: 2.5rem;
      margin-top: 0;
      animation: ${moveToRight} 0.5s;
    }
  }
`;
