import * as S from "./styles";
import Book from "../book";
import { useTranslation } from "../../contexts/BooksContext";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { createContext, useEffect, useRef, useState } from "react";
import BarraPesquisa from "../../components/search/BookSearch";
import { AudioService, Livro } from "../../services/api";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import { HeaderBook } from "../../components/HeaderBook";
import { Header } from "../../components/Header";
import BookAudio from "../../components/bookAudio";
import BookPageAudio from "../../components/BookPageAudio/BookPageAudio";
import { BannerAudio } from "../../components/BannerAudio";
import ClipLoader from "react-spinners/ClipLoader";
interface BookAudioProps {
  playAgora?: (id: string) => void;
}

const AudioBooks = ({ playAgora }: BookAudioProps) => {
  const { selectedLanguage } = useTranslation();
  const navigate = useNavigate();

  // const handleLerAgora = (id: number) => {
  //   navigate(`/book/${id}`);
  // };
  const [loading, setLoading] = useState<boolean>(true);
  const [audioUrl, setUrlAudio] = useState<Livro[]>([]);
  const [filteredBooks, setFilteredBooks] = useState<Livro[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    if (selectedLanguage) {
      const fetchBooks = async () => {
        setLoading(true);
        try {
          const url = `/books-audios?language=${selectedLanguage}`;

          const response = await AudioService.getBooks(selectedLanguage);

          setUrlAudio(response); // Armazena todos os livros
          setFilteredBooks(response); // Exibe todos os livros inicialmente
        } catch (error) {
          console.error("Erro ao buscar livros:", error);
        } finally {
          setLoading(false); // Finaliza o carregamento
        }
      };
      fetchBooks();
    }
  }, [selectedLanguage]);

  const handleBaixar = (url: string) => {
    window.open(url, "_blank");
  };

  const resources = "Todos os livros para Ouvir";

  const handleSearchBooks = (query: string) => {
    setSearchQuery(query);
    const filtered = audioUrl.filter(
      (book) =>
        book.title.toLowerCase().includes(query.toLowerCase()) || // Filtra por título
        book.description?.toLowerCase().includes(query.toLowerCase()) || // Filtra por subtítulo
        book.category.toLowerCase().includes(query.toLowerCase()) // Filtra por categoria
    );

    setFilteredBooks(filtered);
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayer = () => {
    setIsPlaying((prev: any) => !prev);
  };

  const handleAbrirVideo = (id: string, urlAudio: string) => {
    navigate(`/books-audios2/${id}/${urlAudio}`);
  };

  return (
    <S.Container>
      <div className="border-header"></div>
      <BannerAudio />
      {/* downloadBooks */}
      <S.Content id="cards">
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>
              {/* <h2>Todos os livros para Baixar</h2> */}
              {resources}
            </S.ContentHeader>
            <BarraPesquisa onSearch={handleSearchBooks} />
          </S.ContentTitle>
        </S.Title>

        {loading ? (
          <S.Loading className="loading-spinner">
            <ClipLoader color="#36d7b7" loading={loading} size={50} />
            <p> Carregando livros...</p>
          </S.Loading>
        ) : (
          <S.PlayButton>
            <BookAudio
              livros={filteredBooks}
              handleLerAgora={(id, urlAudio) => {
                if (typeof urlAudio === "string") {
                  handleAbrirVideo(id, urlAudio);
                } else {
                  console.error(
                    "Áudio não encontrado para o livro com ID:",
                    id
                  );
                }
              }}
              showArrows={false}
              flexWrap="wrap"
            />
          </S.PlayButton>
        )}
      </S.Content>
    </S.Container>
  );
};

export default AudioBooks;
