import { Toggle } from "../Toggle";
import { Container, Nav, ThemeToggleWrapper, User } from "./styles";

import { useState } from "react";
import { FaChevronRight, FaRegUserCircle } from "react-icons/fa";

// import { AuthContext } from "../../contexts/AuthContexts";
import { useTheme } from "../../hooks/theme";
import LanguageSelector from "../LanguageSelector";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import LanguageSelectorRead from "../LanguageSelectorRead";
import { Link } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";
import { CgClose } from "react-icons/cg";
export const HeaderRead = () => {
  // const { user, signOut } = useContext(AuthContext);

  const { toggleTheme, theme } = useTheme();
  const [darkTheme, setDarkTheme] = useState(() =>
    theme.title === "dark" ? true : false
  );

  const handleChangeTheme = () => {
    setDarkTheme(!darkTheme);
    toggleTheme();
  };
  const { language } = useTranslations();

  const menuTranslations = {
    // home: translations[language].menu.home,
    // about: translations[language].menu.about,
    // myBooks: translations[language].menu.myBooks,
    // saudacao: translations[language].saudacao,
  };

  const [open, setOpen] = useState(false);
  const currentPath = window.location.pathname;
  const openBar = () => {
    setOpen(!open);
  };

  const saudacao = "Olá";
  const menu = "Resumos";
  const menu2 = "Baixar";
  const menu3 = "AudioBooks";

  return (
    <Container>
      <User> {saudacao}👋</User>

      <Nav className={open ? "open" : ""}>
        <li>
          <Link
            to="/books-summary"
            className={currentPath === "/books-summary" ? "active" : ""}
            onClick={openBar}
          >
            {menu}
          </Link>
          <Link
            to="/"
            className={currentPath === "/" ? "active" : ""}
            onClick={openBar}
          >
            {menu2}
          </Link>
          <Link
            to="/books-audios"
            className={currentPath === "/books-audios" ? "active" : ""}
            onClick={openBar}
          >
            {menu3}
          </Link>
          <ThemeToggleWrapper className="toggle-des">
            <Toggle checked={darkTheme} onChange={handleChangeTheme} />
          </ThemeToggleWrapper>
        </li>
      </Nav>
      <LanguageSelectorRead />
      {open ? (
        <CgClose className="baricon" onClick={openBar} />
      ) : (
        <CiMenuKebab className="baricon" onClick={openBar} />
      )}
    </Container>
  );
};
