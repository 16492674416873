import constantes from "./constantes";
import HttpService from "./HtppService"; // Corrigi o nome para "HttpService"

// Interface para a resposta de login
interface LoginResponse {
  data: { user: User; token: string };
}

interface User {
  id: string;
  name: string;
  email: string;
}

// Classe AuthService
class AuthService {
  static client = new HttpService(constantes.urlApi);

  static async login(email: string, password: string): Promise<LoginResponse> {
    try {
      const response = await this.client.post<
        { email: string; password: string },
        LoginResponse
      >(`/auth/login`, {
        email,
        password,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || "Erro ao fazer login");
      } else {
        throw new Error("Erro desconhecido ao fazer login");
      }
    }
  }

  static async register(
    name: string,
    email: string,
    password: string
  ): Promise<LoginResponse> {
    try {
      const response = await this.client.post<
        { name: string; email: string; password: string },
        LoginResponse
      >(`/auth/register`, {
        name,
        email,
        password,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || "Erro ao cadastrar usuário");
      } else {
        throw new Error("Erro desconhecido ao cadastrar usuário");
      }
    }
  }
}

// Interface para a resposta de livros
export interface Livro {
  urlAudio: string;
  _id: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
}

// Classe BookService
class BookService {
  static client = new HttpService(constantes.urlApi);

  static async getBooks(language?: string): Promise<Livro[]> {
    try {
      const response = await this.client.get<Livro[]>("/books", {
        language,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || "Erro ao buscar livros");
      } else {
        throw new Error("Erro desconhecido ao buscar livros");
      }
    }
  }
}

class AudioService {
  static client = new HttpService(constantes.urlApi);

  static async getBooks(language?: string): Promise<Livro[]> {
    try {
      const response = await this.client.get<Livro[]>("/books-audios", {
        language,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || "Erro ao buscar livros");
      } else {
        throw new Error("Erro desconhecido ao buscar livros");
      }
    }
  }
}

export { AuthService, BookService, AudioService };
