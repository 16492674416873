// src/components/LanguageSelector/index.tsx
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../contexts/BooksContext";

import {
  SelectorContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  FlagImage,
} from "./styles";
import { BookService, Livro } from "../../services/api";

// Defina o tipo correto para o idioma
type Language = "Portuguese" | "English" | "Spanish";

const LanguageSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [books, setBooks] = useState<Livro[]>([]); // Lista de livros
  // const [selectedLanguage, setSelectedLanguage] =
  //   useState<Language>("Português");
  const { selectedLanguage, setLanguage } = useTranslation();
  // Fetch livros do banco
  // useEffect(() => {
  //   if (selectedLanguage) {
  //     const fetchBooks = async () => {
  //       try {
  //         const url = `/books?language=${selectedLanguage}`;
  //         console.log("Requisição URL: ", url);
  //         const response = await BookService.getBooks(url);
  //       } catch (error) {
  //         console.error("Erro ao buscar livros:", error);
  //       }
  //     };
  //     fetchBooks();
  //   }
  // }, [selectedLanguage]);

  // Idiomas disponíveis
  const languages = [
    { value: "Portuguese", label: "Português", flag: "/brasil.png" },
    { value: "English", label: "English", flag: "/ingles.png" },
    { value: "Spanish", label: "Español", flag: "/espanhol.png" },
  ];

  const handleSelect = (value: Language) => {
    setLanguage(value);
    setIsOpen(false);
    setLanguage(value);
  };

  const selectedLanguageData = languages.find(
    (lang) => lang.value === selectedLanguage
  );

  // Filtra os livros com base no idioma selecionado
  // const filteredBooks = books?.filter(
  //   (book) => book.language === selectedLanguage
  // );

  return (
    <SelectorContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedLanguageData && (
          <FlagImage
            src={selectedLanguageData.flag}
            alt={selectedLanguageData.label}
          />
        )}{" "}
        {selectedLanguageData?.value}
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {languages?.map((lang) => (
            <DropdownItem
              key={lang.value}
              onClick={() => handleSelect(lang.value as Language)} // Passando o tipo correto
            >
              <FlagImage src={lang.flag} alt={lang.label} />
              {lang?.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </SelectorContainer>
  );
};

export default LanguageSelector;
