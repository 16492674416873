import { styled } from "styled-components";

export const Container = styled.section`
  background: ${(props) => props.theme.colors.secondary};
  /* width: 100%;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; */
  margin: 0;
  padding: 0;
`;
