import { useTheme } from "./hooks/theme";
import { AppRoutes } from "./routes/routes";
import { ThemeProvider } from "styled-components";
import "./index.css";

import GlobalStyles from "./styles/GlobalStyles";
// import { BooksProvider } from "./contexts/BooksContext";
// import { TranslationProvider } from "./contexts/TranslationContext";

function App() {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
