import React, { useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Container, Content } from "./styles";
import BookCard from "../../components/BookCard";

interface Livro {
  _id: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
}

interface BookCarouselProps {
  livros: Livro[];
  handleLerAgora: (id: number) => void;
  handleBaixar?: (url: string) => void;
  showArrows?: boolean;
  flexWrap?: "wrap" | "nowrap";
}

const BookCarousel: React.FC<BookCarouselProps> = ({
  livros,
  handleLerAgora,
  handleBaixar,
  showArrows,
  flexWrap,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemWidth = 320;

  const handleScrollLeft = () => {
    const newIndex = (currentIndex - 1 + livros.length) % livros.length;
    setCurrentIndex(newIndex);
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: -itemWidth, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    const newIndex = (currentIndex + 1) % livros.length;
    setCurrentIndex(newIndex);
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: itemWidth, behavior: "smooth" });
    }
  };

  return (
    <Container>
      {showArrows && (
        <FaAngleLeft className="left" onClick={handleScrollLeft} />
      )}
      <Content ref={contentRef} flexWrap={flexWrap}>
        {livros?.map((livro) => (
          <BookCard
            key={livro?._id}
            urlImage={livro?.urlImage}
            title={livro?.title}
            subtitle={livro?.subtitle}
            description={livro?.description}
            tipo={"download"}
            onAcao={function (
              tipo: "ler" | "baixar" | "download" | "descargar"
            ): void {
              throw new Error("Function not implemented.");
            }}
            _id={livro?._id}
            type={livro?.type}
            urlFile={livro?.urlFile}
            category={livro?.category}
            language={livro?.language}
          />
        ))}
      </Content>
      {showArrows && (
        <FaAngleRight className="right" onClick={handleScrollRight} />
      )}
    </Container>
  );
};

export default BookCarousel;
