import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";

import {
  Container,
  Title,
  Paragraph,
  Header,
  InButton,
  DeButton,
  ContentButtons,
  Content,
  Card,
} from "./styles";
import { Toggle } from "../Toggle";
import { useTheme } from "../../hooks/theme";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import VideoPlayer from "../VideoPlayer";
const BookPageAudio: React.FC = () => {
  const { id, urlAudio } = useParams<{ id: string; urlAudio: string }>();

  const [open, setOpen] = useState(false);

  const [fontSize, setFontSize] = useState<number>(16);
  const { toggleTheme, theme } = useTheme();
  const [darkTheme, setDarkTheme] = useState(() =>
    theme.title === "dark" ? true : false
  );
  const handleChangeTheme = () => {
    setDarkTheme(!darkTheme);
    toggleTheme();
  };

  const openBar = () => {
    setOpen(!open);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Content>
        <CiMenuKebab onClick={openBar} className="baricon" />
        {open && (
          <Card>
            <ContentButtons>
              <div className="contentTheme">
                <Toggle checked={darkTheme} onChange={handleChangeTheme} />
                <p className="title">Alterar Thema</p>
              </div>
            </ContentButtons>
          </Card>
        )}

        <div>
          {/* Adiconar noticias recentes */}
          <Title></Title>

          <Paragraph
            fontSize={fontSize}
            style={{
              whiteSpace: "pre-line",
              marginBottom: "1em",
            }}
          ></Paragraph>
          {urlAudio ? (
            <VideoPlayer src={urlAudio} />
          ) : (
            <p>URL do áudio não encontrada.</p>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default BookPageAudio;
