import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Estilos das setas */
  .left,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    color: ${(props) => props.theme.colors.tertiary};
    cursor: pointer;
    z-index: 1;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
`;

interface ContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flexDirection?: "row" | "column";
  flexWrap?: "wrap" | "nowrap";
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
@media (max-width: 1200px) {
    margin: auto;
    align-items: center;
    justify-content: center;
  }
`;
export const Loading = styled.div`
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  p {
    color: ${(props) => props.theme.colors.font};
  }
`;
