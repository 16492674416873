import { useTranslations } from "../../contexts/TranslationContext";
import translations from "../../data/translations";
import {
  Container,
  BannerImage,
  TextOverlay,
  OverlayBackground,
} from "./styles";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { ImDownload } from "react-icons/im";
import { TiInfoLarge } from "react-icons/ti";

export const BannerBaixar = () => {
  const { language } = useTranslations();

  const layoutTranslations = {
    titlebanner: translations[language].layout.titlebanner,
    descBanner: translations[language].layout.descBanner,
  };

  return (
    <Container>
      <BannerImage
        src="libros-capa.jpg"
        alt="Melhores Livros de Desenvolvimento Pessoal"
      />

      <OverlayBackground />
      <TextOverlay>
        <ImDownload className="icon" />

        <h1>Baixar</h1>

        <p>Baixe seus livros favoritos e leia quando e onde quiser! </p>

        <span className="obs">
          <strong>
            <TiInfoLarge className="icon-info" />
            <h3> AVISO IMPORTANTE! </h3>
          </strong>
          <p className="Info-des">
            Estão sendo adicionados novos livros à plataforma todos os dias. Em
            breve, teremos mais de 1.000 títulos disponíveis.
          </p>
          <p className="Info-des">
            A plataforma está em desenvolvimento e trará novas melhorias nas
            próximas versões
          </p>
        </span>
      </TextOverlay>
    </Container>
  );
};
