import * as S from "./styles";
import Book from "../book";
import { Banner } from "../../components/Banner";
import translations from "../../data/translations";
import { useTranslations } from "../../contexts/TranslationContext";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import BarraPesquisa from "../../components/search/BookSearch";
import BookRead from "../bookRead";
import BookCardAudio from "../../components/BookCardAudio/BookCardAudio";

const HomePage = () => {
  const { language } = useTranslations();
  const resources = translations[language].resources;

  const livros = translations[language].books;
  const navigate = useNavigate();
  const handleLerAgora = (id: number) => {
    navigate(`/books-summary/${id}`);
    // Aqui você pode implementar a lógica para começar a leitura
  };

  const handleBaixar = (url: string) => {
    window.open(url, "_blank");
  };

  const videoContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollLeft = () => {
    if (videoContainerRef.current) {
      videoContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    if (videoContainerRef.current) {
      videoContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const [filteredBooks, setFilteredBooks] = useState(
    livros?.downloadBooks || []
  );
  const [filterPersuBook, setFilterPersuBook] = useState(
    livros?.persuasionBook || []
  );
  const [filterReadBooks, setFilterReadBooks] = useState(
    livros?.readBooks || []
  );
  const [filterEsBook, setFilterEsBook] = useState(livros?.esBook || []);
  const [filterStudyTip, setFilterStudyTip] = useState(livros?.studyTips || []);

  useEffect(() => {
    setFilterReadBooks(livros?.readBooks || []);
    setFilterStudyTip(livros?.studyTips || []);
  }, [language, livros]);

  const handleSummaryBooks = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const allBooks = [...(livros?.readBooks || [])];

    const filtered = allBooks.filter((book) =>
      book.titulo.toLowerCase().includes(lowerCaseQuery)
    );
    setFilterReadBooks(filtered);
  };

  const handleStudyTips = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const allBooks = [...(livros?.studyTips || [])];

    const filtered = allBooks.filter((book) =>
      book.titulo.toLowerCase().includes(lowerCaseQuery)
    );
    setFilterStudyTip(filtered);
  };

  return (
    <S.Container>
      <div className="border-header"></div>
      <Banner />
      <div className="border-header"></div>

      {/* summaryBooks ler */}
      <S.Content>
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>
              {resources.summaryBooks}{" "}
              <img src="brasil.png" width="20px" alt="brasil" />{" "}
              <img src="espanhol.png" width="20px" alt="espanhol" />{" "}
              <img src="ingles.png" width="20px" alt="ingles" />{" "}
            </S.ContentHeader>
            <BarraPesquisa onSearch={(query) => handleSummaryBooks(query)} />
          </S.ContentTitle>
        </S.Title>
        {resources!.downloadBooks && (
          <BookRead
            livros={filterReadBooks || []}
            handleLerAgora={handleLerAgora}
            handleBaixar={handleBaixar}
            tipo="ler"
          />
        )}
      </S.Content>

      {/* studyTips ler */}
      <S.Content>
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>
              {resources.studyTips}{" "}
              <img src={resources.flag} width="20px" alt="espanhol" />{" "}
              <img src="ingles.png" width="20px" alt="ingles" />{" "}
            </S.ContentHeader>
            <BarraPesquisa onSearch={(query) => handleStudyTips(query)} />
          </S.ContentTitle>
        </S.Title>
        {resources!.downloadBooks && (
          <BookRead
            livros={filterStudyTip || []}
            handleLerAgora={handleLerAgora}
            handleBaixar={handleBaixar}
            tipo="ler"
          />
        )}
      </S.Content>
    </S.Container>
  );
};

export default HomePage;
